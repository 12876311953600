<template>
    <q-card>
        <q-card-section>
            <q-toolbar>
                <q-toolbar-title>
                    Usuario
                </q-toolbar-title>
                <q-btn v-if="usuario._id == ''" dense round color="green" icon="mdi-content-save" class="q-mr-sm"
                    @click="guardar()">
                    <q-tooltip>
                        Guardar
                    </q-tooltip>
                </q-btn>

                <q-btn v-else round color="orange" dense icon="mdi-content-save" class="q-mr-sm" @click="PutUsuario()">
                    <q-tooltip>
                        Editar
                    </q-tooltip>
                </q-btn>
                <q-btn round color="red" icon="mdi-close" dense @click="cierraVentana()">
                    <q-tooltip>
                        Cerrar
                    </q-tooltip>
                </q-btn>
            </q-toolbar>
            <q-separator class="full-width" color="primary" inset size="4px" />
            <q-card-section style="max-height: 700px; min-height: 400px;" class="scroll">
                <div class="row q-col-gutter-sm">
                    <div class="col-12  ">
                        <q-input v-model="usuario.username" dense filled label="Nombre de usuario" />
                    </div>

                    <div class="col-12 ">
                        <q-input v-model="usuario.nombre" dense filled label="Nombre" />
                    </div>
                    <div class="col-12  ">

                        <q-input v-model="usuario.apellido" dense filled label="Apellidos" />
                    </div>
                    <div class="col-12  ">
                        <q-select dense class="full-width" v-model="usuario.genero" filled
                            :options="['Femenino', 'Masculino']" label="Genero" />
                    </div>
                    <div class="col-12 ">
                        <q-input v-model="usuario.telefono" dense filled label="Teléfono" />
                    </div>
                    <div class="col-12  ">
                        <q-input v-model="usuario.email" dense filled label="Email" />
                    </div>
                    <div class="col-12 ">
                        <q-input v-model="fechaMasked" filled label="Fecha de nacimiento" name="event" outlined dense>
                            <template v-slot:before>
                                <q-icon name="event" color="primary" />
                            </template>
                            <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                                <q-date v-model="usuario.fechaNacimiento">
                                    <div class="row items-center justify-end">
                                        <q-btn v-close-popup label="Ok" color="primary" flat />
                                    </div>
                                </q-date>
                            </q-popup-proxy>
                        </q-input>
                    </div>
                    <div class="col-12  ">
                        <q-input dense v-model="usuario.password" filled :type="isPwd ? 'password' : 'text'"
                            label="Password">
                            <template v-slot:append>
                                <q-icon :name="isPwd ? 'visibility_off' : 'visibility'" class="cursor-pointer"
                                    @click="isPwd = !isPwd" />
                            </template>
                        </q-input>
                    </div>
                    <div class="col-12 ">
                        <q-select dense class="full-width" v-model="usuario.group" filled :options="['admin', 'usuario']"
                            label="Rol" />
                    </div>
                </div>
            </q-card-section>
        </q-card-section>
    </q-card>
</template>
<script>
import moment from 'moment';
import axios from 'axios'
import { QSpinnerCube } from 'quasar'

export default {

    data() {
        return {
            isPwd: true,
        }
    },
    computed: {
        usuario() {
            return this.$store.state.usuarioStore;
        },
        fechaMasked() {
            moment.locale('es-mx');
            return this.usuario.fechaNacimiento ? moment.utc(this.usuario.fechaNacimiento).format('DD/MMMM/yyyy') : ''
        },
    },

    created() {
    },
    methods: {
        async guardar() {

            console.log(this.usuario)
            if (this.usuario.nombreuser == '') {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique el nombre del usuario' })
                return;
            }
            if (this.usuario.nombre == '') {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique su nombre' })
                return;
            }
            if (this.usuario.apellido == '') {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique sus apellidos' })
                return;
            }
            if (this.usuario.password == '') {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique el password' })
                return;
            }
            if (this.usuario.genero == '') {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique su genero' })
                return;
            }
            if (this.usuario.group == '') {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique el rol del usuario' })
                return;
            }
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'white', spinnerSize: 140, message: 'Guardando usuario. Espere...', messageColor: 'white' })

            try {
                let response = await axios.post('Login/PostEmpleado/dev_ventas/', this.usuario)
                console.log(response)

                this.usuario._id = response.data
                this.$store.state.listaUsuariosStore.push(this.usuario)
                this.cierraVentana();

                this.$q.loading.hide()
                this.$q.notify({ type: 'positive', message: `El usuario ha sido guardado exitosamente.` })
            } catch (error) {
                console.log(error)
                this.$q.loading.hide()
                this.$q.notify({ type: 'negative', message: 'Error al guardar, verifique su informacion e intentelo de nuevo.', color: 'red' })
            }
        },
        cierraVentana() {
            this.$emit('cierraVentana')
        },

        async PutUsuario() {
            this.$q.loading.show({ spinner: QSpinnerCube, spinnerColor: 'white', spinnerSize: 140, message: 'Guardando usuario. Espere...', messageColor: 'white' })
            if (this.usuario.nombreuser == '') {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique el nombre del usuario' })
                return;
            }
            if (this.usuario.nombre == '') {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique su nombre' })
                return;
            }
            if (this.usuario.apellido == '') {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique sus apellidos' })
                return;
            }
            if (this.usuario.password == '') {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique el password' })
                return;
            }
            if (this.usuario.genero == '') {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique su genero' })
                return;
            }
            if (this.usuario.group == '') {
                this.$q.notify({ type: 'warning', position: 'top-right', message: 'Indique el rol del usuario' })
                return;
            }
            try {
                let response = await axios.put('Login/PutEmpleado/dev_ventas', this.usuario)
                console.log(response)

                let indice = this.$store.state.listaUsuariosStore.findIndex(x => x._id === this.usuario._id);
                Object.assign(this.$store.state.listaUsuariosStore[indice], this.usuario)

                this.$q.loading.hide()
                this.$q.notify({ type: 'positive', message: `El usuario ha sido actualizado exitosamente.` })
                this.cierraVentana();
            } catch (error) {
                console.log(error)
                this.$q.loading.hide()
                this.$q.notify({ type: 'negative', message: 'Error al actualizar, verifique su información e intentelo de nuevo.', color: 'red' })
            }
        },
    }
}
</script>
<style></style>