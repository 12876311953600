import jsPDF from 'jspdf';
import moment from 'moment'

function formatoNumerico(value) {
    return value.toLocaleString('en-US', { currency: 'USD' });
}

function formtaoFecha(value) {
    moment.locale('es');
    return moment(value).format('DD-MMMM-YYYY HH:mm:ss')
}

const Reporte = async (montoVentas, periodoTranscurrido, periodoRestante, total, presupuestoGlobal, porcentaje, valor) => {
    return new Promise((resolve, reject) => {
        try {
            const doc = new jsPDF({
                format: 'letter'
            });

            //CABECERA 
            let xCabecera = 5;
            let yCabecera = 10;
            let color = '#027BE3'

            //LOGO
            const rutaImagen = require('../../assets/Logos-04.png');
            doc.addImage(rutaImagen, 'PNG', 20, 5, 58, 20);

            //DATOS DE LA EMPRESA Y POLIZA
            doc.setFontSize(14);
            doc.setFont('helvetica', 'bold');
            doc.text('ESTIMACIÓN DE VENTAS', 190, 12, { align: 'right' });
            doc.setFontSize(10);

            let fecha = new Date()
            doc.text(formtaoFecha(fecha), 190, yCabecera += 7, { align: 'right' });


            //DATOS DE LA EMPRESA Y POLIZA
            // doc.setFontSize(19);
            // doc.setFont('helvetica', 'bold');
            // doc.text('Estimación de Ventas', 20, 20, { align: 'left' });
            // doc.setFontSize(11);
            // doc.setFont('helvetica', 'normal');
            // let fecha = new Date()
            // doc.text(formtaoFecha(fecha), 190, 20, { align: 'right' });

            // VENTAS
            const width = 201;
            const height = 3;
            doc.setFillColor(color);
            doc.rect(20, 25, 170, height, 'F');

            doc.setFont('helvetica', 'bold')
            doc.setFontSize(12);
            doc.setTextColor('#000')
            doc.text('Monto de ventas', 23, 35, { align: 'left' });
            doc.text(formatoNumerico(montoVentas), 189, 35, { align: 'right' });

            doc.setFont('helvetica', 'bold')
            doc.setFontSize(12);
            doc.setTextColor('#000')
            doc.text('Número de periodos transcurridos', 23, 40, { align: 'left' });
            doc.text(formatoNumerico(periodoTranscurrido), 189, 40, { align: 'right' });

            doc.setFont('helvetica', 'bold')
            doc.setFontSize(12);
            doc.setTextColor('#000')
            doc.text('Número de periodos restantes', 23, 45, { align: 'left' });
            doc.text(formatoNumerico(periodoRestante), 189, 45, { align: 'right' });

            doc.setFont('helvetica', 'bold')
            doc.setFontSize(12);
            doc.setTextColor('#000')
            doc.text('Presupuesto global', 23, 50, { align: 'left' });
            doc.text(formatoNumerico(presupuestoGlobal), 189, 50, { align: 'right' });

            doc.setFont('helvetica', 'normal')
            doc.setFontSize(9);
            doc.setTextColor('#767676')
            doc.text('Monto de ventas entre periodos transcurridos = promedio de ventas por periodo', 23, 60, { align: 'left' });
            let a = montoVentas / periodoTranscurrido

            var title = formatoNumerico(montoVentas) + " / " + formatoNumerico(periodoTranscurrido) + " = " + formatoNumerico(a);
            console.log(title)
            doc.setFont('helvetica', 'bold')
            doc.setFontSize(13);
            doc.setTextColor('#000')

            var pageWidth = doc.internal.pageSize.width;
            var titleWidth = doc.getStringUnitWidth(title) * doc.internal.getFontSize() / doc.internal.scaleFactor;
            var x = (pageWidth - titleWidth) / 2;
            var y = 65;
            doc.text(title, x, y);

            doc.setFont('helvetica', 'normal')
            doc.setFontSize(9);
            doc.setTextColor('#767676')
            doc.text('Promedio por el número de periodos restantes = pronóstico de ventas del resto del año', 23, 75, { align: 'left' });
            let b = a * periodoRestante

            var title = formatoNumerico(a) + " * " + formatoNumerico(periodoRestante) + " = " + formatoNumerico(b);
            console.log(title)
            doc.setFont('helvetica', 'bold')
            doc.setFontSize(13);
            doc.setTextColor('#000')

            var pageWidth = doc.internal.pageSize.width;
            var titleWidth = doc.getStringUnitWidth(title) * doc.internal.getFontSize() / doc.internal.scaleFactor;
            var x = (pageWidth - titleWidth) / 2;
            var y = 80;
            doc.text(title, x, y);


            doc.setFont('helvetica', 'normal')
            doc.setFontSize(9);
            doc.setTextColor('#767676')
            doc.text('Monto de ventas actual más el pronóstico del resto del año = pronóstico total', 23, 90, { align: 'left' });

            var title = formatoNumerico(montoVentas) + " + " + formatoNumerico(b) + " = " + formatoNumerico(total);
            console.log(title)
            doc.setFont('helvetica', 'bold')
            doc.setFontSize(13);
            doc.setTextColor('#000')

            var pageWidth = doc.internal.pageSize.width;
            var titleWidth = doc.getStringUnitWidth(title) * doc.internal.getFontSize() / doc.internal.scaleFactor;
            var x = (pageWidth - titleWidth) / 2;
            var y = 95;
            doc.text(title, x, y);

            var title = "Total";
            console.log(title)
            doc.setFont('helvetica', 'bold')
            doc.setFontSize(16);
            doc.setTextColor('#241B5E')

            var pageWidth = doc.internal.pageSize.width;
            var titleWidth = doc.getStringUnitWidth(title) * doc.internal.getFontSize() / doc.internal.scaleFactor;
            var x = (pageWidth - titleWidth) / 2;
            var y = 105;
            doc.text(title, x, y);

            var title = formatoNumerico(total);
            console.log(title)
            doc.setFont('helvetica', 'bold')
            doc.setFontSize(16);
            doc.setTextColor('#C10015')

            var pageWidth = doc.internal.pageSize.width;
            var titleWidth = doc.getStringUnitWidth(title) * doc.internal.getFontSize() / doc.internal.scaleFactor;
            var x = (pageWidth - titleWidth) / 2;
            var y = 110;
            doc.text(title, x, y);

            const width2 = 201;
            const height2 = 8;
            var x = (pageWidth - 80) / 2;

            let colorBarra = '#027BE3'
            let valorP = valor * 100
            if (valorP > 0 && valorP <= 20) {
                colorBarra = '#F44336'
            }
            else if (valorP > 20 && valorP <= 40) {
                colorBarra = '#FF5722'
            }
            else if (valorP > 40 && valorP <= 60) {
                colorBarra = '#FFC107'
            }
            else if (valorP > 60 && valorP <= 80) {
                colorBarra = '#CDDC39'
            }
            else if (valorP > 80) {
                colorBarra = '#4CAF50'
            }

            doc.setFillColor(colorBarra);
            doc.rect(x, 115, 80, height2, 'F');

            doc.setFont('helvetica', 'bold')
            doc.setFontSize(16);
            doc.setTextColor('#fff')
            var titleWidth = doc.getStringUnitWidth(porcentaje) * doc.internal.getFontSize() / doc.internal.scaleFactor;
            var x = (pageWidth - titleWidth) / 2;
            doc.text(porcentaje, x, 121);


            const base64 = doc.output('datauristring');
            doc.save('Estimación de ventas.pdf');
            resolve(base64);

        } catch (error) {
            console.log(error)
        }
    })
}

export {
    Reporte
}