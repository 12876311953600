import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import store from '../store/index'
import ListaUsuarios from '../components/Usuarios/ListaUsuarios.vue'

Vue.use(VueRouter)

const routes = [
  { path: '/', component: Home, name: 'Home', meta: { Administrador: true } },
  { path: '/ListaUsuarios', component: ListaUsuarios, name: 'ListaUsuarios', meta: { Administrador: true } },

  { path: '/Login', name: 'Login', component: Login, meta: { libre: true } },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.libre)) {
    next()
  } else if (store.state.usuario && store.state.usuario.group == 'admin') {
    if (to.matched.some(record => record.meta.Administrador)) {
      next()
    }
  }
  else if (store.state.usuario && store.state.usuario.group == 'usuario') {
    if (to.matched.some(record => record.meta.Administrador)) {
      next()
    }
  }
  else {
    next({
      name: 'Login'
    })
  }
})
export default router
