<template>
  <q-page class=" q-pa-lg ">

    <!-- <q-layout> -->
    <!-- <q-header elevated>
      <q-toolbar style="background-color: #213343;" class="text-white q-py-md ">
        <q-space></q-space>
        <div class="text-h3 text-white">
          Estimación de ventas
        </div>
        <q-space></q-space>
      </q-toolbar>
    </q-header> -->
    <div id="fondo"></div>
    <q-page-container class="container">
      <div class="row q-pa-sm q-pt-lg q-mt-xl  q-col-gutter-sm 	flex-center ">
        <div class="col-12 col-md-3">
          <!-- <q-input bg-color="white" type="number" v-model="montoVentas" outlined label="Monto de ventas" /> -->
          <q-field outlined label="Monto de ventas" v-model="montoVentas">
            <template v-slot:control="{ id, floatingLabel, value, emitValue }">
              <Money :id="id" class="q-field__input text-right" :value="value" @input="emitValue" v-bind="moneyFormat"
                v-show="floatingLabel" />
            </template>
          </q-field>
        </div>
      </div>
      <div class="row q-pa-sm q-col-gutter-sm 	flex-center ">
        <div class="col-12 col-md-3">
          <!-- <q-input bg-color="white" type="number" v-model="periodoTrancurrido" outlined label="Periodos transcurridos" /> -->
          <q-field outlined label="Periodos transcurridos" v-model="periodoTrancurrido">
            <template v-slot:control="{ id, floatingLabel, value, emitValue }">
              <Money :id="id" class="q-field__input text-right" :value="value" @input="emitValue" v-bind="moneyFormat2"
                v-show="floatingLabel" />
            </template>
          </q-field>
        </div>
      </div>
      <div class="row q-pa-sm q-col-gutter-sm 	flex-center ">
        <div class="col-12 col-md-3">
          <!-- <q-input bg-color="white" type="number" v-model="periodoRestante" outlined
            label="Número de periodos restantes" /> -->
          <q-field outlined label="Número de periodos restantes" v-model="periodoRestante">
            <template v-slot:control="{ id, floatingLabel, value, emitValue }">
              <Money :id="id" class="q-field__input text-right" :value="value" @input="emitValue" v-bind="moneyFormat2"
                v-show="floatingLabel" />
            </template>
          </q-field>
        </div>
      </div>
      <div class="row q-pa-sm q-col-gutter-sm 	flex-center ">
        <div class="col-12 col-md-3">
          <!-- <q-input bg-color="white" type="number" v-model="periodoRestante" outlined
            label="Número de periodos restantes" /> -->
          <q-field outlined label="Presupuesto global" v-model="presupuestoGlobal">
            <template v-slot:control="{ id, floatingLabel, value, emitValue }">
              <Money :id="id" class="q-field__input text-right" :value="value" @input="emitValue" v-bind="moneyFormat"
                v-show="floatingLabel" />
            </template>
          </q-field>
        </div>
      </div>
      <div class="row q-pa-sm q-col-gutter-sm flex-center ">
        <div class="col-12 col-md-3">
          <q-btn unelevated color="primary" class="full-width" size="lg" label="CALCULAR" @click="calcular()" />
        </div>
      </div>
      <div class="row q-pa-sm q-col-gutter-sm flex-center ">
        <div class="col-12 col-md-3">
          <!-- <q-input bg-color="white" readonly type="number" outlined v-model="total" label="Pronóstico total"> -->
          <q-field filled label="Pronóstico total" v-model="total">
            <template v-slot:control="{ id, floatingLabel, value, emitValue }">
              <Money :id="id" class="q-field__input text-right" :value="value" @input="emitValue" v-bind="moneyFormat"
                v-show="floatingLabel" />
            </template>
            <template v-slot:after>
              <q-btn round dense flat icon="mdi-delete-empty" @click="borrar()" />
            </template>
          </q-field>
        </div>
      </div>
      <div class="row q-pa-sm q-col-gutter-sm flex-center ">
        <div class="col-12 col-md-3">
          <q-linear-progress size="30px" :value="progress2" :color="colorBarra" class="q-mt-sm">
            <div class="absolute-full flex flex-center">
              <q-badge color="white" :text-color="colorBarra" :label="progressLabel2" />
            </div>
          </q-linear-progress>
        </div>
      </div>
      <q-page-sticky position="bottom-right" :offset="[18, 18]">
        <q-btn fab icon="mdi-export" color="green" @click="ExportarArchivos()" />
      </q-page-sticky>
    </q-page-container>

    <!-- </q-layout> -->
  </q-page>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'
import { Reporte } from '../components/PDF/ReportePDF.js'
import print from 'print-js'
import { Money } from 'v-money'
import * as XLSX from 'xlsx';

export default {
  name: 'Home',
  components: {
    HelloWorld, Money
  },
  data() {
    return {
      progress2: 0.3,
      colorBarra: 'red',
      montoVentas: 0,
      periodoTrancurrido: 0,
      periodoRestante: 0,
      total: 0,
      presupuestoGlobal: 0,

      moneyFormat: {
        decimal: '.',
        thousands: ',',
        precision: 2,
        masked: false
      },

      moneyFormat2: {
        decimal: '.',
        thousands: ',',
        precision: 0,
        masked: false
      },
    }
  },
  computed: {


    progressLabel2() {
      if (this.total !== 0) {
        const porcentaje = (this.total / this.presupuestoGlobal) * 100;
        this.progress2 = porcentaje / 100

        if (porcentaje > 0 && porcentaje <= 20) {
          this.colorBarra = 'red'
        }
        else if (porcentaje > 20 && porcentaje <= 40) {
          this.colorBarra = 'deep-orange'
        }
        else if (porcentaje > 40 && porcentaje <= 60) {
          this.colorBarra = 'amber'
        }
        else if (porcentaje > 60 && porcentaje <= 80) {
          this.colorBarra = 'lime'
        }
        else if (porcentaje > 80) {
          this.colorBarra = 'green'
        }
        return porcentaje.toFixed(2) + '%'
      } else {
        this.progress2 = 0;
        return '0 %';
      }
    }
  },
  methods: {
    calcular() {
      let a = 0
      a = parseFloat(this.montoVentas) / parseFloat(this.periodoTrancurrido);

      let b = 0;
      b = a * parseFloat(this.periodoRestante)

      this.total = parseFloat(this.montoVentas) + b
    },
    borrar() {
      this.montoVentas = 0,
        this.periodoTrancurrido = 0,
        this.periodoRestante = 0,
        this.total = 0
      this.presupuestoGlobal = 0
    },
    async ExportarArchivos() {
      this.GenerarPdf();
      this.GenerarExcel();
    },
    async GenerarPdf() {

      let base64 = await Reporte(this.montoVentas, this.periodoTrancurrido, this.periodoRestante, this.total, this.presupuestoGlobal, this.progressLabel2, this.progress2);
      console.log(base64)
      let documento = base64
      print({
        printable: documento.split(",")[1],
        type: 'pdf',
        base64: true,
      })
    },
    async salir() {
      this.$q.loading.show({ message: '<b>Cerrando Sesión...</b>' })
      try {
        this.$q.loading.hide()
        this.$store.dispatch("salir");
      } catch (err) {
        console.log(err);
        this.$q.loading.hide()
      }
    },

    async GenerarExcel() {
      const hoy = new Date();
      const dia = hoy.getDate();
      const mes = hoy.getMonth() + 1; // ¡Atención! Los meses comienzan desde 0
      const año = hoy.getFullYear();

      const fechaHoy = `${año}-${mes < 10 ? '0' : ''}${mes}-${dia < 10 ? '0' : ''}${dia}`;

      let lista = []

      var obj = {
        Titulo: 'ESTIMACIÓN VENTAS',
        Fecha: fechaHoy
      }
      lista.push(obj)

      var objVacia = {
        Titulo: ' ',
        Fecha: ' '
      }
      lista.push(objVacia)
      lista.push(objVacia)

      var obj = {
        Titulo: 'MONTO DE VENTAS',
        Fecha: this.montoVentas
      }
      lista.push(obj)

      var obj = {
        Titulo: 'PERIODOS TRANSCURRIDOS',
        Fecha: this.periodoTrancurrido
      }
      lista.push(obj)

      var obj = {
        Titulo: 'PERIODOS RESTANTES',
        Fecha: this.periodoRestante
      }
      lista.push(obj)

      var obj = {
        Titulo: 'PRESUPUESTO GLOBAL',
        Fecha: this.presupuestoGlobal
      }
      lista.push(obj)

      var objVacia = {
        Titulo: ' ',
        Fecha: ' '
      }
      lista.push(objVacia)

      var obj = {
        Titulo: 'Monto de ventas entre periodos transcurridos = promedio de ventas por periodo',
        Fecha: ''
      }
      lista.push(obj)
      var resul = 0
      resul = this.montoVentas / this.periodoTrancurrido
      var obj = {
        Titulo: this.montoVentas + ' / ' + this.periodoTrancurrido,
        Fecha: resul
      }
      lista.push(obj)

      var objVacia = {
        Titulo: ' ',
        Fecha: ' '
      }
      lista.push(objVacia)

      var obj = {
        Titulo: 'Promedio por el número de periodos restantes = pronóstico de ventas del resto del año',
        Fecha: ''
      }
      lista.push(obj)
      var resul2 = 0
      resul2 = resul * this.periodoRestante
      var obj = {
        Titulo: resul + ' * ' + this.periodoTrancurrido,
        Fecha: resul2
      }
      lista.push(obj)

      var objVacia = {
        Titulo: ' ',
        Fecha: ' '
      }
      lista.push(objVacia)

      var obj = {
        Titulo: 'Monto de ventas actual más el pronóstico del resto del año = pronóstico total',
        Fecha: ''
      }
      lista.push(obj)
      var resul3 = 0
      resul3 = this.montoVentas + resul2
      var obj = {
        Titulo: this.montoVentas + ' + ' + resul2,
        Fecha: resul3
      }
      lista.push(obj)

      var obj = {
        Titulo: 'TOTAL',
        Fecha: resul3
      }
      lista.push(obj)

      const hojaCalculo = XLSX.utils.json_to_sheet(lista);
      const libroTrabajo = XLSX.utils.book_new();

      // Añadir la hoja de cálculo al libro de trabajo
      XLSX.utils.book_append_sheet(libroTrabajo, hojaCalculo, 'Hoja1');

      // Guardar el libro como un archivo XLSX
      const nombreArchivo = 'ESTIMACIÓN DE VENTAS'

      XLSX.writeFile(libroTrabajo, nombreArchivo + '.xlsx');
    },
  }
}
</script>
<style>
#contenido {
  position: relative;
  z-index: 1;
  /* Coloca el contenido delante del fondo */
}

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  /* Evita la barra de desplazamiento horizontal */
}

#fondo {
  position: fixed;
  top: 0;
  left: 0;
  width: 20%;
  /* Ajusta el ancho según tu preferencia */
  height: 100vh;
  /* Establece el alto de la ventana gráfica actual */
  /* Color de fondo para la parte izquierda */
  z-index: -1;
  /* Coloca el fondo detrás del contenido */
  background-image: url('../assets/fondo.jpg');
  background-size: cover;
  background-repeat: no-repeat;
}
</style>
</style>
